<template>
    <div>
        <el-select :ref="treeId" v-model="selectData" :placeholder="placeholder" clearable @on-clear="clear">
            <el-option v-for="item in list" style="display: none;" :key="item.id" :value="item.id"
                    :label="item.name"></el-option>
            <el-tree
                    auto-expand-parent
                    :current-node-key="current"
                    :default-expanded-keys="expanded"
                    :data="data"
                    node-key="id"
                    highlight-current
                    @node-click="nodeClick"
                    :props="defaultProps"
            ></el-tree>
        </el-select>
    </div>
</template>

<script>

     function treeToList(tree,list) {
         if(tree!=null){
             for (var i =0;i<tree.length;i++){
                 list.push(tree[i])
                 if(tree[i].children!=null && tree[i].children.length>0){
                     treeToList(tree[i].children,list)
                 }
             }
         }

    }

    export default {
        name: 'select-tree',
        props: {
            value: {},
            data: {
                type: Array
            },
            defaultProps: {
                type: Object,
                default(){
                    return {
                        children: 'children',
                        label: 'name'
                    }
                }
            },
            placeholder: {
                type: String,
                default(){
                    return '请选择'
                }
            },
            dataKey:{
                default(){
                    return 'id'
                }
            },
            last:{
                type: Boolean,
                default(){
                    return false
                }
            },
            site:{
                type: Boolean,
                default(){
                    return false
                }
            }
        },
        computed: {
            list(){
                let list = []
                treeToList(this.data,list)
                return list
            },
            treeId() {
                return `selectTree${this._uid}`
            }
        },
        data () {
            return {
                selectData:null,
                expanded:[],
                current:null
            };
        },
        watch: {
            value () {
                this.selectData = this.value
                this.expanded = [this.value]
                this.current = this.value
            }
        },
        methods: {
            nodeClick(data){
                if(this.site){
                    if(data.infoFlag!=true){
                        return
                    }
                }
                if(this.last){
                    if(data[this.defaultProps.children]==null || data[this.defaultProps.children].length<=0){
                        this.selectData = data[this.dataKey]
                        if(this.value!=this.selectData){
                            this.$emit('input', this.selectData)
                            this.$emit('on-change', this.selectData)
                        }
                    }
                }else {
                    this.selectData = data[this.dataKey]
                    if(this.value!=this.selectData){
                        this.$emit('input', this.selectData)
                        this.$emit('on-change', this.selectData)
                    }
                }
                if(data[this.defaultProps.children]==null || data[this.defaultProps.children].length<=0){
                    this.$refs[this.treeId].toggleMenu();
                }

            },
            clear(){
                this.selectData = null
                this.$emit('input', this.selectData)
                this.$emit('on-change', null)
            }
        },
        mounted () {
            this.selectData = this.value
        }
    };
</script>
<style>

</style>
